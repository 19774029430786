import dayjs from 'dayjs'

export const currencyFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD'
})

export function debounce (func, wait, immediate = false) {
  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export function padZero(input) {
  return ('0' + input).slice(-2)
}

export function getPeriodicityDisplay (interval, period, timeUnit) {

  let periodicity = period * interval;

  if (timeUnit === 'minute') {
    timeUnit = 'M';

    if (periodicity >= 60) {
      // convert to hour
      if (periodicity === 60) {
        periodicity = 1
      } else {
        periodicity = Math.ceil(periodicity / 60)
      }

      if (periodicity >= 24) {
        timeUnit = 'D';
      } else {
        timeUnit = 'H'
      }
    }
  }

  if (timeUnit === 'day' || timeUnit === 'D') {
    if (periodicity % 30 === 0) {
        periodicity = periodicity / 30;
        timeUnit = "MO"
    } else if (periodicity % 7 === 0) {
        periodicity = periodicity / 7;
        timeUnit = "W"
    } else if (periodicity % 24 === 0) {
        periodicity = 1;
        timeUnit = "D"
    } else {
        timeUnit = "D"
    }
  }

  if (timeUnit === 'week' || timeUnit === 'W') {
    if (periodicity % 7 === 0) {
        periodicity = periodicity / 7;
        timeUnit = "W"
    } else if (periodicity % 4 === 0) {
        periodicity = periodicity / 4;
        timeUnit = 'MO'
    } else {
      timeUnit = "W"
    }
  }

  if (timeUnit === 'month' || timeUnit === 'MO') {
    timeUnit = 'MO';
  }

  let periodicityDisplay = periodicity + timeUnit;

  return periodicityDisplay;
}

export function getEarliestDateFromDrawingObject(drawingObj) {
  const drawingDate1 = dayjs(getDate(drawingObj.d0));
  const drawingDate2 = dayjs(drawingObj.d1 ? getDate(drawingObj.d1) : new Date());

  function getDate(YYYYMMDDHHmmSSsss) {
    const year = YYYYMMDDHHmmSSsss.substring(0, 4)
    const month = parseInt(YYYYMMDDHHmmSSsss.substring(4, 6))-1
    const date = YYYYMMDDHHmmSSsss.substring(6, 8)
    const hour = YYYYMMDDHHmmSSsss.substring(8, 10)
    const minute = YYYYMMDDHHmmSSsss.substring(10, 12)
    const second = YYYYMMDDHHmmSSsss.substring(12, 14)
    const milli = YYYYMMDDHHmmSSsss.substring(14, 16)
    return new Date(year, month, date, hour, minute, second, milli)
  }

  return drawingDate1.isBefore(drawingDate2) ? drawingDate1 : drawingDate2

}

export function makeID(length = 5) {
  let result           = '';
  let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export function dayOfWeek(format: 'short' | 'medium' | 'long' = 'short', day = dayjs().day()) {
  let shortDays = ["Su", 'M', 'Tu', 'W', 'Th', 'F', 'S']
  let mediumDays = ["Sun", 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat']
  let longDays = ["Sunday", 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  switch (format) {
    case "short":
      return shortDays[day];
    case "medium":
      return mediumDays[day];
    case "long":
      return longDays[day];
    default:
      return shortDays[day];
  }

}

function isDstUS(date) {
  const year = date.getFullYear();
  const marchSecondSunday = new Date(year, 2, 8); // March 8th is the earliest possible date for the second Sunday
  const novemberFirstSunday = new Date(year, 9, 31); // November 1st is the earliest possible date for the first Sunday

  // Find the second Sunday of March
  marchSecondSunday.setDate(marchSecondSunday.getDate() + (7 - marchSecondSunday.getDay()));

  // Find the first Sunday of November
  novemberFirstSunday.setDate(novemberFirstSunday.getDate() + (7 - novemberFirstSunday.getDay()));

  // Check if the date is between the second Sunday of March and the first Sunday of November
  return date >= marchSecondSunday && date < novemberFirstSunday;
}

export function isDaylightSavingsInEffect(dateInput) {
  // To satisfy the original question
  return isDstUS(dateInput);
}
